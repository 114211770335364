import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters["auth/isAuthenticated"]) {
    next();
    return;
  }
  next("/");
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters["auth/isAuthenticated"]) {
    const user = store.getters["auth/user"];

    if (user.function_role === "distributor") {
      if (to.name !== "Settings" && to.name !== "Warehouse") {
        next("/warehouse");
        return;
      }
    }
    next();
    return;
  }
  next("/login");
};

const routes = [
  {
    path: "/",
    redirect: "/service",
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "Auth" */ "../views/Auth/Login.vue"),
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/password-remind",
    name: "PasswordRemind",
    component: () =>
      import(/* webpackChunkName: "Auth" */ "../views/Auth/PasswordRemind.vue"),
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/password-set",
    name: "PasswordSet",
    component: () =>
      import(/* webpackChunkName: "Auth" */ "../views/Auth/PasswordSet.vue"),
    props: (route) => ({ token: route.query.token }),
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/settings",
    name: "Settings",
    component: () =>
      import(/* webpackChunkName: "Auth" */ "../views/Auth/Settings.vue"),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/warehouse",
    name: "Warehouse",
    component: () =>
      import(
        /* webpackChunkName: "Warehouse" */ "../views/Records/Warehouse/Warehouse.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/warehouse/add",
    name: "WarehouseAdd",
    component: () =>
      import(
        /* webpackChunkName: "Warehouse" */ "../views/Records/Warehouse/WarehouseAdd.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/records/customer",
    name: "Customer",
    component: () =>
      import(
        /* webpackChunkName: "Customer" */ "../views/Records/Customer/Customer.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/records/customer/:id(\\d+)",
    name: "CustomerDetails",
    component: () =>
      import(
        /* webpackChunkName: "Customer" */ "../views/Records/Customer/CustomerDetails.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/records/customer/add",
    name: "CustomerAdd",
    component: () =>
      import(
        /* webpackChunkName: "Customer" */ "../views/Records/Customer/CustomerAdd.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/records/customer/:id(\\d+)/edit",
    name: "CustomerEdit",
    component: () =>
      import(
        /* webpackChunkName: "Customer" */ "../views/Records/Customer/CustomerEdit.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/records/service-technician",
    name: "ServiceTechnician",
    component: () =>
      import(
        /* webpackChunkName: "ServiceTechnician" */ "../views/Records/ServiceTechnician/ServiceTechnician.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/records/service-technician/:id(\\d+)",
    name: "ServiceTechnicianDetails",
    component: () =>
      import(
        /* webpackChunkName: "ServiceTechnician" */ "../views/Records/ServiceTechnician/ServiceTechnicianDetails.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/records/service-technician/add",
    name: "ServiceTechnicianAdd",
    component: () =>
      import(
        /* webpackChunkName: "ServiceTechnician" */ "../views/Records/ServiceTechnician/ServiceTechnicianAdd.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/records/service-technician/:id(\\d+)/edit",
    name: "ServiceTechnicianEdit",
    component: () =>
      import(
        /* webpackChunkName: "ServiceTechnician" */ "../views/Records/ServiceTechnician/ServiceTechnicianEdit.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/records/machinery",
    name: "Machinery",
    component: () =>
      import(
        /* webpackChunkName: "Machinery" */ "../views/Records/Machinery/Machinery.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/records/machinery/:id(\\d+)",
    name: "MachineryDetails",
    component: () =>
      import(
        /* webpackChunkName: "Machinery" */ "../views/Records/Machinery/MachineryDetails.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/records/machinery/add",
    name: "MachineryAdd",
    component: () =>
      import(
        /* webpackChunkName: "Machinery" */ "../views/Records/Machinery/MachineryAdd.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/records/machinery/:id(\\d+)/edit",
    name: "MachineryEdit",
    component: () =>
      import(
        /* webpackChunkName: "Machinery" */ "../views/Records/Machinery/MachineryEdit.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/records/services",
    name: "Services",
    component: () =>
      import(
        /* webpackChunkName: "Services" */ "../views/Records/Services/Services.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/records/services/:id(\\d+)",
    name: "ServicesDetails",
    component: () =>
      import(
        /* webpackChunkName: "Services" */ "../views/Records/Services/ServicesDetails.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/records/services/add",
    name: "ServicesAdd",
    component: () =>
      import(
        /* webpackChunkName: "Services" */ "../views/Records/Services/ServicesAdd.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/records/services/:id(\\d+)/edit",
    name: "ServicesEdit",
    component: () =>
      import(
        /* webpackChunkName: "Services" */ "../views/Records/Services/ServicesEdit.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/records/operators",
    name: "Operators",
    component: () =>
      import(
        /* webpackChunkName: "Operators" */ "../views/Records/Operators/Operators.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/records/operators/:id(\\d+)",
    name: "OperatorsDetails",
    component: () =>
      import(
        /* webpackChunkName: "Operators" */ "../views/Records/Operators/OperatorsDetails.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/records/operators/add",
    name: "OperatorsAdd",
    component: () =>
      import(
        /* webpackChunkName: "Operators" */ "../views/Records/Operators/OperatorsAdd.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/records/operators/:id(\\d+)/edit",
    name: "OperatorsEdit",
    component: () =>
      import(
        /* webpackChunkName: "Operators" */ "../views/Records/Operators/OperatorsEdit.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/service",
    name: "Service",
    component: () =>
      import(/* webpackChunkName: "Service" */ "../views/Service/Service.vue"),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/service/:id(\\d+)",
    name: "ServiceDetails",
    component: () =>
      import(
        /* webpackChunkName: "Service" */ "../views/Service/ServiceDetails.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/service/add",
    name: "ServiceAdd",
    component: () =>
      import(
        /* webpackChunkName: "Service" */ "../views/Service/ServiceAdd.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/service/:id(\\d+)/edit",
    name: "ServiceEdit",
    component: () =>
      import(
        /* webpackChunkName: "Service" */ "../views/Service/ServiceEdit.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/raports",
    name: "Raports",
    component: () =>
      import(/* webpackChunkName: "Raports" */ "../views/Raports.vue"),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/notes",
    name: "Notes",
    component: () =>
      import(/* webpackChunkName: "Notes" */ "../views/Notes/Notes.vue"),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/notes/:id(\\d+)",
    name: "NotesDetails",
    component: () =>
      import(/* webpackChunkName: "Notes" */ "../views/Notes/NotesDetails.vue"),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/notes/add",
    name: "NotesAdd",
    component: () =>
      import(/* webpackChunkName: "Notes" */ "../views/Notes/NotesAdd.vue"),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/notes/:id(\\d+)/edit",
    name: "NotesEdit",
    component: () =>
      import(/* webpackChunkName: "Notes" */ "../views/Notes/NotesEdit.vue"),
    beforeEnter: ifAuthenticated,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  linkActiveClass: "active",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
});

export default router;
